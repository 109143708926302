<template>
  <div v-for="(item, index) in multipleFormData" :key="index">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-4">Form {{ index + 1 }}</h3>
      <div class="d-flex justify-content-between align-items-center gap-2">
        <button
          class="btn w-sm btn-primary waves-effect waves-light px-2"
          @click="copyNewForm(index)"
        >
          Copy
        </button>
        <button
          class="btn w-sm btn-success waves-effect waves-light px-2"
          @click="addnewForm()"
        >
          Add
        </button>
        <button
          @click="deleteForm(index)"
          v-if="index > 0"
          class="btn w-sm btn-danger waves-effect waves-light px-2"
        >
          Delete
        </button>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="name" class="form-label">
          Questions
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-md-6">
        <input
          type="text"
          id="name"
          name="name"
          class="form-control"
          placeholder="Question"
          v-model="item.name"
          @input="(event) => handleChange('Name', index, event)"
        />
        <div class="text-danger" v-if="item.error?.name_error">
          {{ item.error?.name_error }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="program">
          Program Name
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <div class="d-flex">
          <div class="d-flex align-items-center me-3">
            <input
              :checked="item.allCheck"
              @click="
                item.allCheck = true;
                item.program = [];
                item.assessment_criteria_id = '';
                item.assessment_criteria_id_error = '';
                filterAssessmentCriteriaList = [];
                item.error.program_error = '';
                filterAssessmentCriteria(index);
              "
              type="radio"
              :name="`choose-${index}`"
            />
            <label>All</label>
          </div>
          <div class="d-flex align-items-center">
            <input
              :checked="!item.allCheck"
              @click="
                item.allCheck = false;
                item.program = [];
                filterAssessmentCriteriaList = [];
                item.assessment_criteria_id = '';
                item.assessment_criteria_id_error = '';
                item.error.program_error = '';
              "
              type="radio"
              :name="`choose-${index}`"
            />
            <label>Select</label>
          </div>
        </div>

        <v-select
          :closeOnSelect="false"
          v-show="!item.allCheck"
          name="program"
          :clearable="false"
          id="program"
          :options="moduleList"
          label="name"
          :loading="!moduleList.length"
          multiple
          v-model="item.program"
          :selectable="(option) => selectedProgram(option, index)"
          @option:selected="handleChange('Program', index)"
          @option:deselected="handleChange('Program', index)"
          :class="{
            error: item.error?.program_error,
          }"
        />

        <div class="text-danger" v-if="item.error?.program_error">
          {{ item.error?.program_error }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="remark">
          Assessment Criteria
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <v-select
          :clearable="false"
          :options="filterAssessmentCriteriaList"
          label="name"
          :reduce="(val) => val.id"
          v-model="item.assessment_criteria_id"
          @option:selected="handleChange('Assessment Criteria', index)"
          @option:deselected="handleChange('Assessment Criteria', index)"
        />

        <div
          class="text-danger"
          v-if="item.error?.assessment_criteria_id_error"
        >
          {{ item.error?.assessment_criteria_id_error }}
        </div>
      </div>
    </div>

    <div class="float-end me-5">
      <button
        type="button"
        @click="addAnswer(index)"
        class="btn btn-sm btn-blue waves-effect waves-light mb-2"
      >
        Add Answer
      </button>
    </div>

    <div class="row mt-5 text-center">
      <div class="d-flex">
        <div class="col-md-5">
          <label for="name" class="form-label">
            Answer
            <span class="text-danger">*</span>
          </label>
        </div>
        <div class="col-md-5">
          <label for="name" class="form-label">
            Score
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div
        v-for="(answer, subIndex) in item.answers"
        :key="subIndex"
        class="d-flex m-2"
      >
        <div class="col-md-4 me-5 ms-4">
          <input
            type="text"
            id="name"
            name="name"
            class="form-control"
            placeholder="Answer"
            v-model="answer.name"
          />
        </div>

        <div class="col-md-4 me-4">
          <input
            type="number"
            id="score"
            name="score"
            class="form-control"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 && Math.abs(this.value) <=5 ? Math.abs(this.value) : null"
            placeholder="Score"
            v-model="answer.score"
          />
        </div>

        <div class="col-md-2" v-show="subIndex != 0">
          <button
            type="button"
            @click="removeAnswer(subIndex)"
            class="btn btn-sm btn-danger waves-effect waves-light mb-2"
          >
            Remove
          </button>
        </div>
      </div>
    </div>

    <hr />
  </div>

  <div class="row">
    <div class="col-md-6 mx-auto">
      <div class="mt-3 mb-3 ms-3 text-center">
        <router-link :to="{ name: 'hrd-behavior-question' }">
          <button type="button" class="btn w-sm btn-secondary me-5">
            Cancel
          </button>
        </router-link>

        <button
          :disabled="isLoading ? true : false"
          type="button"
          class="btn w-sm btn-success waves-effect waves-light px-3"
          @click="store()"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>

          {{ isLoading == true ? "Loading..." : "Save" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { levels } from "../../../const";
import { types } from "../../../const";

import validationErrors from "../../../mixins/validationErrors";
import { onMounted } from "vue";

export default {
  props: {
    assessmentCriteria: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  components: {
    vSelect,
  },

  mixins: [validationErrors],

  data() {
    return {
      multipleFormData: [
        {
          name: "",
          program: [],
          assessment_criteria_id: "",
          all_check: 1,
          answers: [{ name: "", score: "" }],
          error: {
            name_error: "",
            program_error: "",
            assessment_criteria_id_error: "",
          },
        },
      ],
      filterAssessmentCriteriaList: [],
      isLoading: false,
      allowStore: true,
      moduleList: this.$store.getters["odoo/getAllPrograms"],
      getLevel: levels,
      getType: types,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  methods: {
    addnewForm() {
      this.multipleFormData.push({
        name: "",
        program: [],
        assessment_criteria_id: "",
        all_check: 1,
        answers: [{ name: "", score: "" }],
        error: {
          name_error: "",
          program_error: "",
          assessment_criteria_id_error: "",
        },
      });
    },
    addAnswer(index) {
      if (this.multipleFormData[index].answers === 5) {
        this.toast.error("Maximum Answer Limit is 5 !");
        return;
      } else {
        this.multipleFormData[index].answers.push({
          name: "",
          score: "",
        });
      }
    },
    removeAnswer(index) {
      this.multipleFormData[index].answers.splice(index, 1);
    },
    copyNewForm(index) {
      //DEEP CLONE 
      const copiedObject = JSON.parse(JSON.stringify(this.multipleFormData[index]));
      this.multipleFormData.push(copiedObject);
    },

    deleteForm(index) {
      this.multipleFormData.splice(index, 1);
    },

    selectedProgram(options, index) {
      return !this.multipleFormData[index]?.program?.length
        ? true
        : this.multipleFormData[index].program.find(
            (p) => p.id == options.id
          ) === undefined;
    },

    handleChange(type, index, event) {
      if (type == "Name") {
        this.multipleFormData[index].name = event.target.value;
        if (!this.multipleFormData[index]?.name) {
          this.multipleFormData[index].error.name_error = "Name is required.";
        } else {
          this.multipleFormData[index].error.name_error = "";
        }
      } else if (type == "Program") {
        this.multipleFormData[index].assessment_criteria_id = "";
        this.multipleFormData[index].assessment_criteria_id_error = "";
        this.filterAssessmentCriteriaList = [];
        this.filterAssessmentCriteria(index);
        if (
          this.multipleFormData[index]?.program?.length === 0 &&
          !this.multipleFormData[index]?.allCheck
        ) {
          this.multipleFormData[index].error.program_error =
            "Program is required.";
        } else {
          this.multipleFormData[index].error.program_error = "";
        }
      } else if (type == "Assessment Criteria") {
        if (!this.multipleFormData[index]?.assessment_criteria_id) {
          this.multipleFormData[index].error.assessment_criteria_id_error =
            "Assessment Criteria is required.";
        } else {
          this.multipleFormData[index].error.assessment_criteria_id_error = "";
        }
      }
      // else if (type == "Lowest Score") {
      //   const value = Number(event.target.value);
      //   if (value >= 1 && value <= 5) {
      //     this.multipleFormData[index].lowest_score = value;
      //   } else {
      //     this.multipleFormData[index].lowest_score = null
      //   }

      //   if (!this.multipleFormData[index]?.lowest_score) {
      //     this.multipleFormData[index].error.lowest_score_error = "Lowest Score is required.";
      //   } else {
      //     this.multipleFormData[index].error.lowest_score_error = "";
      //   }
      // } else if (type == "Highest Score") {
      //   const value = Number(event.target.value);
      //   if (value >= 1 && value <= 5) {
      //     this.multipleFormData[index].highest_score = value;
      //   } else {
      //     this.multipleFormData[index].highest_score = null
      //   }
      //   if (!this.multipleFormData[index]?.highest_score) {
      //     this.multipleFormData[index].error.highest_score_error = "Highest Score is required.";
      //   } else {
      //     this.multipleFormData[index].error.highest_score_error = "";
      //   }
      // }
    },

    filterAssessmentCriteria(index) {
      if (this.multipleFormData[index].allCheck) {
        this.filterAssessmentCriteriaList = this.assessmentCriteria.filter(
          (val) => {
            return val.all_check;
          }
        );
      } else {
        this.filterAssessmentCriteriaList = this.assessmentCriteria.filter(
          (val) => {
            const selectedIds = this.multipleFormData[index].program.map(
              (p) => p.id
            );
            const criteriaProgramIds = val.programs.map((p) => p.id);
            return (
              selectedIds.length > 0 &&
              selectedIds.every((id) => criteriaProgramIds.includes(id))
            );
          }
        );
      }
    },

    checkBeforeStore() {
      this.allowStore = true;
      this.multipleFormData.forEach((item) => {
        if (!item.name)
          (item.error.name_error = "Name is required."),
            (this.allowStore = false);
        if (item.program.length == 0 && !item.allCheck)
          (item.error.program_error = "Program is required."),
            (this.allowStore = false);
        if (!item.assessment_criteria_id)
          (item.error.assessment_criteria_id_error =
            "Assessment Criteria is required."),
            (this.allowStore = false);
      });
    },

    checkEmptyValue(array) {
      let answerAndScore = Object.values(array);
      if (answerAndScore.includes(null) || answerAndScore.includes("")) {
        return false;
      } else {
        return true;
      }
    },

    async store() {
      this.checkBeforeStore();
      if (this.allowStore) {
        const formData = [];
        let checkAnswer = [];

        this.multipleFormData.forEach((item) => {

          item?.answers.forEach((array) => {
            let answer = this.checkEmptyValue(array);
            checkAnswer.push(answer);
          });
          if (checkAnswer.includes(false)) {
            return;
          }

          this.isLoading = true;
          
          let data = {
            name: item.name,
            program_ids: item.allCheck
              ? this.moduleList.map((val) => val.id)
              : item.program.map((val) => val.id),
            all_check: item.allCheck ? 1 : 0,
            assessment_criteria_id: item.assessment_criteria_id,
            answers: item.answers
          };

          formData.push(data);
        });

        if (checkAnswer.includes(false)) {
            this.toast.error("Please Fill All Input!");
            return;
        }

        await axios
          .post(`${this.baseUrl}admin/v2/hrd-behavior-question-multiple`, formData)

          .then(() => {
            this.$router.push({ name: "hrd-behavior-question" });
            this.toast.success("Successfully Created HRD Behavior Question!");
          })
          .catch(() => this.toast.error("Something Went Wrong!"));

        this.isLoading = false;
      } else {
        console.log("Not allowed to store,");
      }
    },
  },

  onMounted() {
    this.multipleFormData = [];
    this.filterAssessmentCriteriaList = [];
    this.isLoading = false;
    this.allowStore = true;
  },
};
</script>
